import * as React from "react";
import Layout from "../components/layout";

const SharePage = () => {
  return (
    <Layout>
      {/* <ShareForm /> */}
      <p className="prose prose-xl mt-10">
        We are currently updating our process for collecting stories. Please
        check back soon to share your story, or email us at{" "}
        <a href="mailto:info@projectvoice.org">info@projectvoice.org</a>
      </p>
    </Layout>
  );
};

export default SharePage;
